import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_SUBMISSION_LANG: {
  SUBMISSION: MvnCodeFE;
  SUBMISSION_PAGE_DESCRIPTION: MvnCodeFE;
  SUBMISSION_SUBMIT_BTN: MvnCodeFE;
  SUBMISSION_REGULATORY: MvnCodeFE;
  SUBMISSION_SENDER: MvnCodeFE;
  SUBMISSION_RECEIVER: MvnCodeFE;
  SUBMISSION_BATCH_RECEIVER: MvnCodeFE;
  SUBMISSION_MESSAGE_RECEIVER: MvnCodeFE;
  SUBMISSION_REQUEST_SUCC: MvnCodeFE;
  SUBMISSION_REQUEST_FAIL: MvnCodeFE;
  SUBMISSION_FILE_DOWNLOAD_SUCC: MvnCodeFE;
  SUBMISSION_FILE_DOWNLOAD_FAIL: MvnCodeFE;
} = {
  SUBMISSION: {
    ko: "Submission",
    en: "Submission",
  },
  SUBMISSION_PAGE_DESCRIPTION: {
    ko: "Case를 제출하고 관리할 수 있습니다.",
    en: "Submit and manage submission cases.",
  },
  SUBMISSION_SUBMIT_BTN: {
    ko: "Submit",
    en: "Submit",
  },
  SUBMISSION_REGULATORY: {
    ko: "Regulatory",
    en: "Regulatory",
  },
  SUBMISSION_SENDER: {
    ko: "Sender",
    en: "Sender",
  },
  SUBMISSION_RECEIVER: {
    ko: "Receiver",
    en: "Receiver",
  },
  SUBMISSION_BATCH_RECEIVER: {
    ko: "Batch Receiver",
    en: "Batch Receiver",
  },
  SUBMISSION_MESSAGE_RECEIVER: {
    ko: "Message Receiver",
    en: "Message Receiver",
  },
  SUBMISSION_REQUEST_SUCC: {
    ko: "Submission 요청을 성공했습니다.",
    en: "Submission request has been completed successfully.",
  },
  SUBMISSION_REQUEST_FAIL: {
    ko: "Submission 요청이 실패했습니다. ({message})",
    en: "Failed to request submission. ({message})",
  },
  SUBMISSION_FILE_DOWNLOAD_SUCC: {
    ko: "Submission file 다운로드 요청을 성공했습니다.",
    en: "Submission file download request has been completed successfully.",
  },
  SUBMISSION_FILE_DOWNLOAD_FAIL: {
    ko: "Submission file 다운로드 요청이 실패했습니다. ({message})",
    en: "Failed to download submission file. ({message})",
  },
};

export const SAFETY_SUBMISSION_DIALOG_LANG: {
  SUBMISSION_DIALOG_TITLE: MvnCodeFE;
} = {
  SUBMISSION_DIALOG_TITLE: {
    ko: "Case Submission",
    en: "Case Submission",
  },
};

export const SAFETY_SUBMISSION_INVALID_DIALOG_LANG: {
  SUBMISSION_INVALID_DIALOG_REDIRECT_SUBSCRIPTION_PAGE: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_SUBSCRIPTION_SUBSCRIBE_OTHER_PLAN: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_NOT_ENOUGHT_EXPORT_POINT: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_001: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_002: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_STATUS_CASE_001: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_STATUS_CASE_002: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_WORKFLOW_ASSIGNEE: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_UNBLIND_CASE_001: MvnCodeFE;
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_UNBLIND_CASE_002: MvnCodeFE;
} = {
  SUBMISSION_INVALID_DIALOG_REDIRECT_SUBSCRIPTION_PAGE: {
    ko: "구독 서비스 설명을 위한 안내 페이지로 이동하시겠습니까?",
    en: "Are you sure want to go subscription page?",
  },
  SUBMISSION_INVALID_DIALOG_SUBSCRIPTION_SUBSCRIBE_OTHER_PLAN: {
    ko: "Case Submission 기능을 이용하기 위해서는 다른 구독 상품 가입이 필요합니다.",
    en: "You are not subscribed to submit the case to regulatory.\nYou need to subscribe other plan to submit the case.",
  },
  SUBMISSION_INVALID_DIALOG_NOT_ENOUGHT_EXPORT_POINT: {
    ko: "Case Submission 기능을 이용하기 위한 포인트가 부족합니다.",
    en: "You don't have enough points to submit the case to regulatory.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_001: {
    ko: "Case Submission 기능 이용 권한이 없습니다.",
    en: "You have no permission to submit the case to regulatory.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_002: {
    ko: "관리자에게 'Case Submission' 권한 부여를 요청하시길 바랍니다.",
    en: "If you want to submit the case, please contact administrator to grant privileges of 'Case Submission'.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_STATUS_CASE_001: {
    ko: "Case Submission이 불가한 Case가 포함되어 있습니다.\n아래 Case의 Status를 확인하시기 바랍니다.",
    en: "Some selected cases are not available to submit.\nPlease check the status of the case below.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_STATUS_CASE_002: {
    ko: "'Ready For Submission' 상태의 Case만 제출이 가능합니다.",
    en: "Only cases in the 'Ready For Submission' status can be submitted.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_WORKFLOW_ASSIGNEE: {
    ko: "Case 제출 권한이 없습니다.\n\nCase를 제출하려면, Case Workflow의 'Case Submission' 담당자로 지정되어야 합니다.\n\n관리자에게 문의하시기 바랍니다.",
    en: "You have no permission to submit the case.\n\nIf you want to submit the case, You need to be assigned as the 'Case Submission' person in the case workflow.\n\nPlease contact administrator.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_UNBLIND_CASE_001: {
    ko: "선택된 Case는 Blinded Case입니다.\nUnblind 권한이 없습니다.",
    en: "Selected case(s) is(are) blinded case(s).\nYou have no permission to unblind the case.",
  },
  SUBMISSION_INVALID_DIALOG_INVALID_NOT_AUTHORIZED_UNBLIND_CASE_002: {
    ko: "Case를 제출하려면 관리자에게 Unblind 또는 'Blind Item Edit' 권한 부여를 요청하시기 바랍니다.",
    en: "If you want to submit the case, please contact administrator to\nunblind the case or to grant privileges of 'Blind Item Edit'.",
  },
};

export const SAFETY_SUBMISSION_SELECT_REPORT_DIALOG_LANG: {
  SUBMISSION_SELECT_REPORT_DIALOG_TITLE: MvnCodeFE;
  SUBMISSION_SELECT_REPORT_DIALOG_DESCRIPTION: MvnCodeFE;
} = {
  SUBMISSION_SELECT_REPORT_DIALOG_TITLE: {
    ko: "Select Report Dialog",
    en: "Select Report Dialog",
  },
  SUBMISSION_SELECT_REPORT_DIALOG_DESCRIPTION: {
    ko: "선택된 Case에 Follow-up report가 존재합니다.\n 필요한 Report를 선택하여 Submission을 진행하세요.",
    en: "There are follow-up reports in your cases.\n Please select specific reports to submit.",
  },
};

export const SAFETY_SUBMISSION_RECEIVER_DIALOG_LANG: {
  SUBMISSION_RECEIVER_DIALOG_PLACEHOLDER: MvnCodeFE;
} = {
  SUBMISSION_RECEIVER_DIALOG_PLACEHOLDER: {
    ko: "{key} 정보를 입력해주세요.",
    en: "Please enter a {key} information.",
  },
};

export const SAFETY_SUBMISSION_CONFIRM_DIALOG_LANG: {
  SUBMISSION_CONFIRM_DIALOG_SUBMIT_REGULATORY: MvnCodeFE;
  SUBMISSION_CONFIRM_DIALOG_SELECTED_CASES_COUNT: MvnCodeFE;
  SUBMISSION_CONFIRM_DIALOG_NEW_CHARGED_CASES_COUNT: MvnCodeFE;
  SUBMISSION_CONFIRM_DIALOG_REMAIN_EXPORT_POINTS: MvnCodeFE;
  SUBMISSION_CONFIRM_DIALOG_COMMENT_LABEL: MvnCodeFE;
  SUBMISSION_CONFIRM_DIALOG_COMMENT_PLACEHOLDER: MvnCodeFE;
} = {
  SUBMISSION_CONFIRM_DIALOG_SUBMIT_REGULATORY: {
    ko: "{regulatory}에 선택된 Case를 제출하시겠습니까?",
    en: "Are you sure you want to submit selected case to {regulatory}?",
  },
  SUBMISSION_CONFIRM_DIALOG_SELECTED_CASES_COUNT: {
    ko: "선택된 case(s): ",
    en: "Selected case(s): ",
  },
  SUBMISSION_CONFIRM_DIALOG_NEW_CHARGED_CASES_COUNT: {
    ko: "포인트가 사용되는 새롭게 제출되는 case(s): ",
    en: "New case(s) to be charged: ",
  },
  SUBMISSION_CONFIRM_DIALOG_REMAIN_EXPORT_POINTS: {
    ko: "제출 이후 {orgName}의 남은 포인트: ",
    en: "Remain points in {orgName} after submission: ",
  },
  SUBMISSION_CONFIRM_DIALOG_COMMENT_LABEL: {
    ko: "Comment",
    en: "Comment",
  },
  SUBMISSION_CONFIRM_DIALOG_COMMENT_PLACEHOLDER: {
    ko: "제출할 case(s)에 대한 comment를 입력하세요.",
    en: "Please enter a comment for submitted cases.",
  },
};

export const SAFETY_SUBMISSION_CASE_REPORT_SUBMISSION_HISTORY_DIALOG_LANG: {
  SUBMISSION_CASE_REPORT_SUBMISSION_HISTORY_DIALOG_TITLE: MvnCodeFE;
} = {
  SUBMISSION_CASE_REPORT_SUBMISSION_HISTORY_DIALOG_TITLE: {
    ko: "{caseName} Submission History",
    en: "{caseName} Submission History",
  },
};

export const SAFETY_SUBMISSION_ACK_FAILED_DIALOG_LANG: {
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_TITLE: MvnCodeFE;
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_DESCRIPTION_001: MvnCodeFE;
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_DESCRIPTION_002: MvnCodeFE;
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_DELETED_CASE_TOOPTIP_CONTENT: MvnCodeFE;
} = {
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_TITLE: {
    ko: "Acknowledge File Error",
    en: "Acknowledge File Error",
  },
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_DESCRIPTION_001: {
    ko: "해당 Submission 건은 연결 문제(인터넷 연결 오류, Maven Safety와 {regulatory} 사이의 데이터 전송 오류 등)로 인하여 정상적으로 제출되지 못하였습니다.\n\n",
    en: "The submission case was not submitted successfully due to connection errors. (Internet connection error, data transmission error between Maven Safety and {regulatory}.)\n\n",
  },
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_DESCRIPTION_002: {
    ko: "연결 오류 확인 후 다시 Submission 진행이 필요합니다.",
    en: "Please check the connection error and submit the cases again.",
  },
  SAFETY_SUBMISSION_ACK_FAILED_DIALOG_DELETED_CASE_TOOPTIP_CONTENT: {
    ko: "삭제된 Case/Report입니다.",
    en: "Case/Report is deleted.",
  },
};

MvnCodeInitiate(SAFETY_SUBMISSION_LANG);

MvnCodeInitiate(SAFETY_SUBMISSION_DIALOG_LANG);
MvnCodeInitiate(SAFETY_SUBMISSION_INVALID_DIALOG_LANG);
MvnCodeInitiate(SAFETY_SUBMISSION_SELECT_REPORT_DIALOG_LANG);
MvnCodeInitiate(SAFETY_SUBMISSION_RECEIVER_DIALOG_LANG);
MvnCodeInitiate(SAFETY_SUBMISSION_CONFIRM_DIALOG_LANG);

MvnCodeInitiate(SAFETY_SUBMISSION_CASE_REPORT_SUBMISSION_HISTORY_DIALOG_LANG);
