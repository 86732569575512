import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeBE } from "../const";

export const SAFETY_SUBMISSION_CODE: {
  SUBMISSION_INVALID_MULTIPLE_PER_CASE: MvnCodeBE;
  SUBMISSION_INVALID_CRF: MvnCodeBE;
  SUBMISSION_INVALID_EXPORT_SPEC_NOT_FOUND: MvnCodeBE;
  SUBMISSION_INVALID_VALIDATION_FAILED: MvnCodeBE;
  SUBMISSION_INVALID_WORKFLOW_STATUS: MvnCodeBE;
  SUBMISSION_INVALID_WORKFLOW_ASSIGNEE: MvnCodeBE;
  SUBMISSION_INVALID_PRIV_REQUIRED: MvnCodeBE;
  SUBMISSION_INVALID_RECEIVERS: MvnCodeBE;
  SUBMISSION_INVALID_DUPLICATED_SENDER_REPORT_IDENTIFIER: MvnCodeBE;
  SUBMISSION_FILE_DOWNLOAD_URL_EXPIRED: MvnCodeBE;
} = {
  SUBMISSION_INVALID_MULTIPLE_PER_CASE: {
    status: 400,
    ko: "Case당 한 개의 Report만 제출 가능합니다.",
    en: "Only one report can be submitted per case.",
  },
  SUBMISSION_INVALID_CRF: {
    status: 400,
    ko: "Submission을 지원하지 않는 CRF 입니다.",
    en: "Crf does not support submission.",
  },
  SUBMISSION_INVALID_EXPORT_SPEC_NOT_FOUND: {
    status: 400,
    ko: "Export spec 정보가 존재하지 않습니다.",
    en: "Export spec does not exist.",
  },
  SUBMISSION_INVALID_VALIDATION_FAILED: {
    status: 400,
    ko: "Case Report Validate에 실패했습니다.",
    en: "Failed to validate reports.",
  },

  SUBMISSION_INVALID_WORKFLOW_STATUS: {
    status: 400,
    ko: "{status} 상태의 Report만 제출 가능합니다.",
    en: "Only {status} report can be submitted.",
  },
  SUBMISSION_INVALID_WORKFLOW_ASSIGNEE: {
    status: 400,
    ko: "Assign된 Report만 제출 가능합니다.",
    en: "Only assigned report can be submitted.",
  },
  SUBMISSION_INVALID_PRIV_REQUIRED: {
    status: 403,
    ko: "{priv} 권한이 필요합니다.",
    en: "{priv} privilege is required to submit reports.",
  },
  SUBMISSION_INVALID_RECEIVERS: {
    status: 400,
    ko: "Receiver 검증에 실패했습니다.",
    en: "Failed to validate receivers.",
  },
  SUBMISSION_INVALID_DUPLICATED_SENDER_REPORT_IDENTIFIER: {
    status: 400,
    ko: "Report의 Sender Report Identifer가 Unique해야 합니다.",
    en: "Sender report identifier of report must be unique.",
  },
  SUBMISSION_FILE_DOWNLOAD_URL_EXPIRED: {
    status: 409,
    ko: "Submission 파일 다운로드 URL이 만료되었습니다.",
    en: "Submission file download URL has expired.",
  },
};

MvnCodeInitiate(SAFETY_SUBMISSION_CODE);
