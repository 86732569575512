/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCode, MvnCodeInitiate } from "../lib/const";

export const COMMON_LANG: {
  SAVE: MvnCodeFE;
  CLOSE: MvnCodeFE;
  CLEAR: MvnCodeFE;
  REMOVE: MvnCodeFE;
  INSERT: MvnCodeFE;
  CONFIRM: MvnCodeFE;
  CONFIRM_ALTER: MvnCodeFE;
  CANCEL: MvnCodeFE;
  CANCEL_ALTER: MvnCodeFE;
  VERIFY: MvnCodeFE;
  UPLOAD: MvnCodeFE;
  DOWNLOAD: MvnCodeFE;
  DELETE: MvnCodeFE;
  RETRIEVE: MvnCodeFE;
  RECOVER: MvnCodeFE;
  RESTORE: MvnCodeFE;
  ADD: MvnCodeFE;
  CREATE: MvnCodeFE;
  INVITE: MvnCodeFE;
  SHARING: MvnCodeFE;
  EDIT: MvnCodeFE;
  REGISTER: MvnCodeFE;
  PURCHASE: MvnCodeFE;
  COMPARE: MvnCodeFE;
  ACCEPT: MvnCodeFE;
  APPROVE: MvnCodeFE;
  REJECT: MvnCodeFE;
  STATUS: MvnCodeFE;
  ACTIVE: MvnCodeFE;
  INACTIVE: MvnCodeFE;
  YES: MvnCodeFE;
  NO: MvnCodeFE;
  GENERATING: MvnCodeFE;
  GET_STARTED: MvnCodeFE;
  UPGRADE: MvnCodeFE;
  PASSWORD_PLACEHOLDER: MvnCodeFE;
  BACK: MvnCodeFE;
  BACK_TO_LIST: MvnCode;
  BACK_TO_PREVIOUS: MvnCodeFE;
  PLURAL: MvnCodeFE;
  SUCCEED: MvnCodeFE;
  NOT_SELECTED_ITEMS: MvnCodeFE;
  HISTORY: MvnCodeFE;
  SCHEDULE: MvnCodeFE;
  REQUEST: MvnCodeFE;
  BULK_REQUEST: MvnCodeFE;
  COMPLETE: MvnCodeFE;
  PARTIALLY_COMPLETE: MvnCodeFE;
  INCOMPLETE: MvnCodeFE;
  IN_PROGRESS: MvnCodeFE;
  ERROR: MvnCodeFE;
  SEARCH: MvnCodeFE;
  MALE: MvnCodeFE;
  FEMALE: MvnCodeFE;
  SEX: MvnCodeFE;
  AGE: MvnCodeFE;
  ANSWER: MvnCodeFE;
  NORMAL_RANGE: MvnCodeFE;
  TEMPORARY_SAVE: MvnCodeFE;
  REQUEST_NOT_AUTHORIZED: MvnCodeFE;
  REQUEST_FAIL_MSG_AUTHENTICATION_FAIL: MvnCodeFE;
  REQUEST_FAIL_MSG_USER_ACCOUNT_LOCK: MvnCodeFE;
  REQUEST_FAIL_MSG_INVALID_COUNT: MvnCodeFE;
  COUNT_SUFFIX: MvnCodeFE;
  YEAR: MvnCodeFE;
  MONTH: MvnCodeFE;
  IMPORT: MvnCodeFE;
  IMPORTING: MvnCodeFE;
  EXPORT: MvnCodeFE;
  ENTER: MvnCodeFE;
  FAILED: MvnCodeFE;
  DONE: MvnCodeFE;
  NEW: MvnCodeFE;
  SELECT: MvnCodeFE;
  SELECT_ALL: MvnCodeFE;
  DESELECT_ALL: MvnCodeFE;
  MOVE: MvnCodeFE;
  CURRENCY_KRW: MvnCodeFE;
  AUDIT_TRAIL: MvnCodeFE;
  OK: MvnCodeFE;
  SEND: MvnCodeFE;
  SEND_CANCEL: MvnCodeFE;
  DATE: MvnCodeFE;
  CAN_NOT_BE_DISPLAY_AGAIN_TODAY: MvnCodeFE;
  USER: MvnCodeFE;
  ROLE: MvnCodeFE;
  COPY: MvnCodeFE;
  TRASH: MvnCodeFE;
  LOCK: MvnCodeFE;
  LOCKED: MvnCodeFE;
  UNLOCK: MvnCodeFE;
  UNLOCKED: MvnCodeFE;
  PREV: MvnCodeFE;
  NEXT: MvnCodeFE;
  VALIDATE: MvnCodeFE;
  RESULT: MvnCodeFE;
  FILE: MvnCodeFE;
  NAME: MvnCodeFE;
  EMAIL: MvnCodeFE;
  DO_NOT_SHOW_TODAY: MvnCodeFE;
  REASON: MvnCodeFE;
} = {
  SAVE: {
    ko: "Save",
    en: "Save",
  },
  CLOSE: {
    ko: "Close",
    en: "Close",
  },
  CLEAR: {
    ko: "Clear",
    en: "Clear",
  },
  REMOVE: {
    ko: "Remove",
    en: "Remove",
  },
  INSERT: {
    ko: "Insert",
    en: "Insert",
  },
  CONFIRM: {
    ko: "Confirm",
    en: "Confirm",
  },
  CONFIRM_ALTER: {
    ko: "확인",
    en: "Confirm",
    fr: "Confirmer",
    nl: "Bevestigen",
  },
  CANCEL: {
    ko: "Cancel",
    en: "Cancel",
  },
  CANCEL_ALTER: {
    ko: "취소",
    en: "Cancel",
  },
  VERIFY: {
    ko: "Verify",
    en: "Verify",
  },
  UPLOAD: {
    ko: "Upload",
    en: "Upload",
  },
  DOWNLOAD: {
    ko: "Download",
    en: "Download",
  },
  YES: {
    ko: "Yes",
    en: "Yes",
  },
  NO: {
    ko: "No",
    en: "No",
  },
  GET_STARTED: {
    ko: "Get Started",
    en: "Get Started",
  },
  GENERATING: {
    ko: "생성 중 ..",
    en: "Generating ..",
  },
  UPGRADE: {
    ko: "Upgrade",
    en: "Upgrade",
  },
  DELETE: {
    ko: "Delete",
    en: "Delete",
  },
  RETRIEVE: {
    ko: "회수",
    en: "Retrieve",
  },
  RECOVER: {
    ko: "Recover",
    en: "Recover",
  },
  RESTORE: {
    ko: "Restore",
    en: "Restore",
  },
  ADD: {
    ko: "Add",
    en: "Add",
  },
  CREATE: {
    ko: "Create",
    en: "Create",
  },
  INVITE: {
    ko: "Invite",
    en: "Invite",
  },
  SHARING: {
    ko: "Sharing",
    en: "Sharing",
  },
  EDIT: {
    ko: "Edit",
    en: "Edit",
  },
  REGISTER: {
    ko: "Register",
    en: "Register",
  },
  PURCHASE: {
    ko: "Purchase",
    en: "Purchase",
  },
  COMPARE: {
    ko: "Compare",
    en: "Compare",
  },
  ACCEPT: {
    ko: "Accept",
    en: "Accept",
  },
  APPROVE: {
    ko: "Approve",
    en: "Approve",
  },
  REJECT: {
    ko: "Reject",
    en: "Reject",
  },
  STATUS: {
    ko: "Status",
    en: "Status",
  },
  ACTIVE: {
    ko: "Active",
    en: "Active",
  },
  INACTIVE: {
    ko: "Inactive",
    en: "Inactive",
  },
  PASSWORD_PLACEHOLDER: {
    ko: "**********",
    en: "**********",
  },
  BACK: {
    ko: "Back",
    en: "Back",
  },
  BACK_TO_LIST: {
    ko: "Back to List",
    en: "Back to List",
  },
  BACK_TO_PREVIOUS: {
    ko: "Back to Previous",
    en: "Back to Previous",
  },
  PLURAL: {
    ko: "",
    en: "s",
  },
  SUCCEED: {
    ko: "성공",
    en: "Succeed",
  },
  NOT_SELECTED_ITEMS: {
    ko: "선택된 항목이 없습니다.",
    en: "No selected items.",
  },
  HISTORY: {
    ko: "History",
    en: "History",
  },
  SCHEDULE: {
    ko: "Schedule",
    en: "Schedule",
  },
  REQUEST: {
    ko: "Request",
    en: "Request",
  },
  BULK_REQUEST: {
    ko: "{requestCount}개의 요청 건 중 {successCount}개의 요청이 성공했습니다.",
    en: "{successCount} out of {requestCount} requests were successfully.",
  },
  COMPLETE: {
    ko: "완료",
    en: "Complete",
    fr: "Terminé",
    nl: "Voltooid",
  },
  PARTIALLY_COMPLETE: {
    ko: "부분완료",
    en: "Patially complete",
  },
  INCOMPLETE: {
    ko: "미완료",
    en: "Incomplete",
    fr: "Incomplet",
    nl: "Onvoltooid",
  },
  IN_PROGRESS: {
    ko: "진행 중",
    en: "In Progress",
    fr: "En cours",
    nl: "In uitvoering",
  },
  ERROR: {
    ko: "서버와의 통신이 원활하지 않습니다. ({message})",
    en: "An error has occurred. ({message})",
  },
  SEARCH: {
    ko: "검색",
    en: "Search",
  },
  MALE: {
    ko: "남자",
    en: "Male",
  },
  FEMALE: {
    ko: "여자",
    en: "Female",
  },
  SEX: {
    ko: "성별",
    en: "Sex",
  },
  AGE: {
    ko: "나이",
    en: "Age",
  },
  ANSWER: {
    ko: "답변",
    en: "Answer",
  },
  NORMAL_RANGE: {
    ko: "정상범위",
    en: "Normal Range",
  },
  TEMPORARY_SAVE: {
    ko: "임시저장",
    en: "Temporary Save",
  },
  REQUEST_FAIL_MSG_AUTHENTICATION_FAIL: {
    ko: "이메일 혹은 비밀번호를 잘못 입력하셨습니다.",
    en: "The email address or password you entered is incorrect.",
  },
  REQUEST_FAIL_MSG_USER_ACCOUNT_LOCK: {
    ko: "로그인 시도 횟수를 초과하여 로그아웃 되었습니다. 비밀번호를 재설정 해주세요.",
    en: "logged out because of too many failed attempts. Please reset your password.",
  },
  REQUEST_FAIL_MSG_INVALID_COUNT: {
    ko: "이메일 혹은 비밀번호를 잘못 입력하셨습니다. 허용 횟수를 초과 할 경우 계정 로그인이 제한됩니다.",
    en: "The email address or password you entered is incorrect. Account will be restricted if you exceed allowed failed attempts.",
  },
  REQUEST_NOT_AUTHORIZED: {
    ko: "{priv} 권한이 존재하지 않습니다.",
    en: "Access denied, You don't have {priv} privilege(s).",
  },
  COUNT_SUFFIX: {
    ko: "건",
    en: " ",
  },
  YEAR: {
    ko: "년",
    en: "Year",
  },
  MONTH: {
    ko: "월",
    en: "Month",
  },
  IMPORT: {
    ko: "Import",
    en: "Import",
  },
  IMPORTING: {
    ko: "Importing",
    en: "Importing",
  },
  EXPORT: {
    ko: "Export",
    en: "Export",
  },
  ENTER: {
    ko: "입력",
    en: "Enter",
  },
  FAILED: {
    ko: "실패",
    en: "Failed",
  },
  DONE: {
    ko: "완료",
    en: "Done",
  },
  NEW: {
    ko: "New",
    en: "New",
  },
  SELECT: {
    ko: "Select",
    en: "Select",
  },
  SELECT_ALL: {
    ko: "전체 선택",
    en: "Select All",
  },
  DESELECT_ALL: {
    ko: "전체 해제",
    en: "Deselect All",
  },
  MOVE: {
    ko: "Move",
    en: "Move",
  },
  CURRENCY_KRW: {
    ko: "원",
    en: "Won",
  },
  AUDIT_TRAIL: {
    ko: "Audit Trail",
    en: "Audit Trail",
  },
  OK: {
    ko: "OK",
    en: "OK",
  },
  SEND: {
    ko: "Send",
    en: "Send",
  },
  SEND_CANCEL: {
    ko: "Send Cancel",
    en: "Send Cancel",
  },
  DATE: {
    ko: "Date",
    en: "Date",
  },
  CAN_NOT_BE_DISPLAY_AGAIN_TODAY: {
    ko: "오늘 다시 표시하지 않음",
    en: "Do not show this message today.",
  },
  USER: {
    ko: "User",
    en: "User",
  },
  ROLE: {
    ko: "Role",
    en: "Role",
  },
  COPY: {
    ko: "Copy",
    en: "Copy",
  },
  TRASH: {
    ko: "Trash",
    en: "Trash",
  },
  LOCK: {
    ko: "Lock",
    en: "Lock",
  },
  LOCKED: {
    ko: "Locked",
    en: "Locked",
  },
  UNLOCK: {
    ko: "Unlock",
    en: "Unlock",
  },
  UNLOCKED: {
    ko: "Unlocked",
    en: "Unlocked",
  },
  PREV: {
    ko: "이전",
    en: "Previous",
    fr: "Précédent",
    nl: "Vorige",
  },
  NEXT: {
    ko: "다음",
    en: "Next",
  },
  VALIDATE: {
    ko: "Validate",
    en: "Validate",
  },
  RESULT: {
    ko: "Result",
    en: "Result",
  },
  FILE: {
    ko: "File",
    en: "File",
  },
  NAME: {
    ko: "Name",
    en: "Name",
  },
  EMAIL: {
    ko: "E-Mail",
    en: "E-Mail",
  },
  DO_NOT_SHOW_TODAY: {
    ko: "오늘 하루 열지 않음",
    en: "Do not show this message today.",
  },
  REASON: {
    ko: "원인",
    en: "Reason",
  },
};

MvnCodeInitiate(COMMON_LANG);
