/* eslint-disable prettier/prettier */
/* prettier-ignore */

import { MvnCodeFE } from "./const";
import { MvnCodeInitiate } from "../lib/const";

export const AUTH_TOKEN_LANG: {
  INITIAL_SETUP_TITLE_PASSWORD_SET: MvnCodeFE;
  INITIAL_SETUP_TITLE_EMAIL_VERIFY: MvnCodeFE;
  UPDATE_SETUP_TITLE_PASSWORD_SET: MvnCodeFE;
  PASSWORD_CHANGE_FAILED: MvnCodeFE;
  EMAIL_SEND_FAILED: MvnCodeFE;
  EMAIL_SEND_SUCCEED: MvnCodeFE;
  VERIFICATION_PLACEHOLDER: MvnCodeFE;
  VERIFICATION_CODE: MvnCodeFE;
  PASSWORD: MvnCodeFE;
  PASSWORD_PLACEHOLDER: MvnCodeFE;
  EMAIL: MvnCodeFE;
  EMAIL_PLACEHOLDER: MvnCodeFE;
  CONFIRM_PASSWORD: MvnCodeFE;
  INITIAL_SETUP_TITLE_EMAIL_SENT: MvnCodeFE;
  INITIAL_SETUP_TITLE_ENTER_PASSWORD: MvnCodeFE;
  VERIFY_LIMIT_EXCEED: MvnCodeFE;
  VERIFICATION_NOT_MATCH: MvnCodeFE;
  PASSWORD_VALIDATION: MvnCodeFE;
  NEW_PASSWORD_PLACEHOLDER: MvnCodeFE;
  NEW_PASSWORD_CONFIRM_PLACEHOLDER: MvnCodeFE;
  EMAIL_VERIFY_SUCCEED: MvnCodeFE;
  REQUEST_VERIFICATION_CODE: MvnCodeFE;
  PASSWORD_NOT_MATCH: MvnCodeFE;
  PASSWORD_CHANGE_SUCCEED: MvnCodeFE;
  VERIFICATION_CODE_EXCEED: MvnCodeFE;
  RECENTLY_USED_PASSWORD: MvnCodeFE;
  CHANGE_PASSWORD_TITLE: MvnCodeFE;
  CHANGE_PASSWORD_DESC: MvnCodeFE;
  FIND_PASSWORD_TITLE: MvnCodeFE;
  FIND_PASSWORD_DESC: MvnCodeFE;
  CHANGE_PASSWORD_INVALID_SAME_EMAIL: MvnCodeFE;
  CHANGE_PASSWORD_INVALID_USED_PASSWORD: MvnCodeFE;
  LOGIN_SUCCESS_TIME: MvnCodeFE;
  LOGIN_DUPLICATE_TEXT: MvnCodeFE;
  LOGIN_EXPIRED_ADDITIONAL_LOGIN_TEXT: MvnCodeFE;
} = {
  INITIAL_SETUP_TITLE_PASSWORD_SET: {
    ko: "초기 비밀번호 변경을 위해 새로운 비밀번호를 입력해주세요.",
    en: "Please enter a new password for initial setup.",
  },
  INITIAL_SETUP_TITLE_EMAIL_VERIFY: {
    ko: "초기 비밀번호 세팅을 위해 이메일 인증을 진행해주십시오.",
    en: "Please verify your email for initial setup.",
  },
  UPDATE_SETUP_TITLE_PASSWORD_SET: {
    ko: "이전 비밀번호 변경 후 90일이 지났습니다. 새로운 비밀번호를 입력해주세요.",
    en: "90days have passed since the last password reset. Please reset your password.",
  },
  INITIAL_SETUP_TITLE_EMAIL_SENT: {
    ko: "이메일 전송을 완료하였습니다. 인증코드를 입력해주십시오.",
    en: "Email has been sent. Please enter the verification code.",
  },
  INITIAL_SETUP_TITLE_ENTER_PASSWORD: {
    ko: "인증코드가 일치합니다. 사용할 비밀번호를 입력해주십시오.",
    en: "Verified. Please enter a new password.",
  },
  PASSWORD_CHANGE_FAILED: {
    ko: "비밀번호 변경을 실패했습니다. ({message})",
    en: "Failed to change password. ({message})",
  },
  EMAIL_SEND_FAILED: {
    ko: "이메일 전송에 실패하였습니다. ({message})",
    en: "Failed to send email. ({message})",
  },
  EMAIL_SEND_SUCCEED: {
    ko: "이메일 전송을 완료하였습니다.",
    en: "Email has been sent.",
  },
  VERIFICATION_PLACEHOLDER: {
    ko: "인증번호를 입력해주세요.",
    en: "Please enter the verification code.",
  },
  VERIFICATION_CODE: {
    ko: "인증번호",
    en: "Verification Code",
  },
  EMAIL: {
    ko: "이메일",
    en: "Email",
  },
  EMAIL_PLACEHOLDER: {
    ko: "이메일을 입력해주세요.",
    en: "Please enter your email address.",
  },
  PASSWORD: {
    ko: "비밀번호",
    en: "Password",
  },
  PASSWORD_PLACEHOLDER: {
    ko: "비밀번호를 입력해주세요.",
    en: "Please enter your password.",
  },
  CONFIRM_PASSWORD: {
    ko: "비밀번호 확인",
    en: "Confirm Password",
  },
  VERIFY_LIMIT_EXCEED: {
    ko: "최대 시도 횟수를 초과하였습니다. 다시 시도해주십시오.",
    en: "You have exceeded the number of attempts. Please try again.",
  },
  VERIFICATION_NOT_MATCH: {
    ko: "인증코드가 일치하지 않습니다. 다시 입력하여 주십시오.",
    en: "Verification code does not match. Please enter again.",
  },
  PASSWORD_VALIDATION: {
    ko: "비밀번호는 최소 8글자 이상, 1개 이상의 숫자, 영문, 특수문자를 포함해야 합니다.",
    en: "Password must contain at least 8 characters, 1 number, 1 English and 1 symbol.",
  },
  NEW_PASSWORD_PLACEHOLDER: {
    ko: "새로운 비밀번호를 입력해주세요.",
    en: "Please enter a new password.",
  },
  NEW_PASSWORD_CONFIRM_PLACEHOLDER: {
    ko: "새로운 비밀번호를 한번 더 입력해주세요.",
    en: "Please confirm a new password.",
  },
  EMAIL_VERIFY_SUCCEED: {
    ko: "인증코드가 확인되었습니다. 비밀번호를 변경해주십시오.",
    en: "Verified. Please change password.",
  },
  REQUEST_VERIFICATION_CODE: {
    ko: "인증코드 요청",
    en: "Request a Verification Code",
  },
  PASSWORD_NOT_MATCH: {
    ko: "비밀번호가 일치하지 않습니다.",
    en: "Password does not match.",
  },
  PASSWORD_CHANGE_SUCCEED: {
    ko: "비밀번호를 변경하였습니다. 로그인해 주십시오.",
    en: "Password has been changed. Please log in.",
  },
  VERIFICATION_CODE_EXCEED: {
    ko: "만료된 코드입니다. 다시 요청하십시오.",
    en: "Verficiation code expired. Please try again.",
  },
  RECENTLY_USED_PASSWORD: {
    ko: "최근 3회 이내 사용한 비밀번호입니다. 다른 비밀번호를 입력해주세요.",
    en: "You have used the same password in last 3 times. Please enter a different password.",
  },
  CHANGE_PASSWORD_TITLE: {
    ko: "비밀번호 재설정",
    en: "Reset Password",
  },
  CHANGE_PASSWORD_DESC: {
    ko: "새로운 비밀번호를 입력해주세요.",
    en: "Please enter a new password.",
  },
  FIND_PASSWORD_TITLE: {
    ko: "Maven CDMS 비밀번호 재설정",
    en: "Reset Maven CDMS Password",
  },
  FIND_PASSWORD_DESC: {
    ko: "비밀번호를 초기화 할 계정의 이메일 주소를 입력해 주세요.",
    en: "Please enter your email address to reset your password.",
  },
  CHANGE_PASSWORD_INVALID_SAME_EMAIL: {
    ko: "이메일과 동일한 비밀번호로 변경할 수 없습니다.",
    en: "You can not change with the same password as your email",
  },
  CHANGE_PASSWORD_INVALID_USED_PASSWORD: {
    ko: "현재 사용중인 비밀번호로는 변경할 수 없습니다.",
    en: "You can not change with your current password",
  },
  LOGIN_SUCCESS_TIME: {
    ko: "최근 로그인 시간",
    en: "Last Login Time",
  },
  LOGIN_DUPLICATE_TEXT: {
    ko: "해당 계정으로 이미 로그인 한 사용자가 있습니다. \n이전 로그인 사용자의 접속을 끊고, 계속 진행하시겠습니까?",
    en: "Previous user with this account exists. \nDiscontinue the previous user and continue with the current account?",
  },
  LOGIN_EXPIRED_ADDITIONAL_LOGIN_TEXT: {
    ko: "현재 로그인 토큰이 신규 로그인으로 인해 유효하지 않습니다. \n로그인 페이지로 돌아갑니다.",
    en: "Your session has expired due to another login with your account. \nReturn to login page.",
  },
};

MvnCodeInitiate(AUTH_TOKEN_LANG);
