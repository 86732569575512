import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const SAFETY_DICTIONARY_LANG: {
  SAFETY_DICTIONARY_SEARCH_PLACEHOLDER: MvnCodeFE;
  SAFETY_MFDS_INGREDIENT_DICTIONARY_INGREDIENT_CODE_LABEL: MvnCodeFE;
  SAFETY_MFDS_INGREDIENT_DICTIONARY_INGREDIENT_NAME_LABEL: MvnCodeFE;
  SAFETY_MFDS_INGREDIENT_DICTIONARY_INGREDIENT_NAME_EN_LABEL: MvnCodeFE;
  SAFETY_MFDS_DRUG_DICTIONARY_DRUG_CODE_LABEL: MvnCodeFE;
  SAFETY_MFDS_DRUG_DICTIONARY_DRUG_NAME_LABEL: MvnCodeFE;
  SAFETY_WHODD_C3_SUN_DICTIONARY_SUBSTANCE_NAME_LABEL: MvnCodeFE;
  SAFETY_EDQM_STANDARD_TERM_DICTIONARY_CONDITION_OF_USE_1: MvnCodeFE;
  SAFETY_EDQM_STANDARD_TERM_DICTIONARY_CONDITION_OF_USE_2: MvnCodeFE;
} = {
  SAFETY_DICTIONARY_SEARCH_PLACEHOLDER: {
    ko: "검색어를 입력해주세요.",
    en: "Type to search.",
  },
  SAFETY_MFDS_INGREDIENT_DICTIONARY_INGREDIENT_CODE_LABEL: {
    ko: "성분코드",
    en: "Ingredient Code",
  },
  SAFETY_MFDS_INGREDIENT_DICTIONARY_INGREDIENT_NAME_LABEL: {
    ko: "성분명",
    en: "Ingredient Name",
  },
  SAFETY_MFDS_INGREDIENT_DICTIONARY_INGREDIENT_NAME_EN_LABEL: {
    ko: "성분영문명",
    en: "Ingredient Name (EN)",
  },
  SAFETY_MFDS_DRUG_DICTIONARY_DRUG_CODE_LABEL: {
    ko: "품목일련번호",
    en: "Drug Code",
  },
  SAFETY_MFDS_DRUG_DICTIONARY_DRUG_NAME_LABEL: {
    ko: "제품명",
    en: "Drug Name",
  },
  SAFETY_WHODD_C3_SUN_DICTIONARY_SUBSTANCE_NAME_LABEL: {
    ko: "성분명",
    en: "Substance Name",
  },
  SAFETY_EDQM_STANDARD_TERM_DICTIONARY_CONDITION_OF_USE_1: {
    ko: "데이터는 http://standardterms.edqm.eu를 기반으로 하며 유럽 평의회(EDQM) 의약품 및 건강 관리 유럽 위원회의 허가를 받아 복제되었습니다.",
    en: "Data is reproduced based on http://standardterms.edqm.eu under the permission of the European Directorate for the Quality of Medicines (EDQM).",
  },
  SAFETY_EDQM_STANDARD_TERM_DICTIONARY_CONDITION_OF_USE_2: {
    ko: "EDQM 표준용어 데이터는 정적인 데이터가 아니며 추후 변경될 수 있습니다.",
    en: "The EDQM Standard Terms database is not a static list and content can change over time.",
  },
};

MvnCodeInitiate(SAFETY_DICTIONARY_LANG);
