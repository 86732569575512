import { MvnCodeInitiate } from "../../lib/const";
import { MvnCodeFE } from "../const";

export const VDR_ROOM_DOCUMENT_LANG: {
  // Description
  FOLDER_NAME_INPUT_DESCRIPTION: MvnCodeFE;
  DRAG_AND_DROP_DESCRIPTION: MvnCodeFE;
  DRAG_AND_DROP_SUB_DESCRIPTION: MvnCodeFE;
  DRAG_AND_DROP_LIMIT: MvnCodeFE;
  DELETE_RESOURCE_DESCRIPTION_1: MvnCodeFE;
  DELETE_RESOURCE_DESCRIPTION_2: MvnCodeFE;
  DUPLICATE_TO_DESCRIPTION: MvnCodeFE;
  UPLOAD_NOT_ALLOWED_DESCRIPTION: MvnCodeFE;
  UPLOAD_SIZE_EXCEEDED_DESCRIPTION: MvnCodeFE;
  PUBLISH_DESCRIPTION_1: MvnCodeFE;
  PUBLISH_DESCRIPTION_2: MvnCodeFE;

  // TITLE
  ROOM_INFO_TITLE: MvnCodeFE;
  ROOM_STATUS_TITLE: MvnCodeFE;
  ADD_FOLDER_TITLE: MvnCodeFE;
  DOWNLOAD_INDEX_TITLE: MvnCodeFE;
  UPLOAD_FILE_TITLE: MvnCodeFE;
  UPLOAD_FOLDER_TITLE: MvnCodeFE;
  COMPRESSING_TITLE: MvnCodeFE;
  COMPRESSED_TITLE: MvnCodeFE;
  DUPLICATE_TO_TITLE: MvnCodeFE;
  SELECT_LOCATION_PLACEHOLDER: MvnCodeFE;
  MOVE_TO_TITLE: MvnCodeFE;
  RENAME_TITLE: MvnCodeFE;
  ENTER_NAME_PLACEHOLDER: MvnCodeFE;
  PUBLISH_TITLE: MvnCodeFE;
  SELECT_FILE_TITLE: MvnCodeFE;
  RETRY_TITLE: MvnCodeFE;
  EMPTY_IMAGE_TITLE: MvnCodeFE;
  EMPTY_CONVERT_FAIL_TITLE: MvnCodeFE;
  EMPTY_LOADING_TITLE: MvnCodeFE;
  EMPTY_UNABLE_TITLE: MvnCodeFE;

  // Toast
  ADD_FOLDER_SUCCESS: MvnCodeFE;
  ADD_FOLDER_FAIL: MvnCodeFE;
  DOWNLOAD_START: MvnCodeFE;
  DOWNLOAD_FAIL: MvnCodeFE;
  DOWNLOAD_UNAUTHORIZED: MvnCodeFE;
  INDEX_DOWNLOAD_START: MvnCodeFE;
  INDEX_DOWNLOAD_SUCCESS: MvnCodeFE;
  INDEX_DOWNLOAD_FAIL: MvnCodeFE;
  DELETE_RESOURCE_SUCCESS: MvnCodeFE;
  DELETE_RESOURCE_FAIL: MvnCodeFE;
  DUPLICATE_TO_SUCCESS: MvnCodeFE;
  DUPLICATE_TO_FAIL: MvnCodeFE;
  UPLOAD_SUCCESS: MvnCodeFE;
  UPLOAD_FAIL: MvnCodeFE;
  MOVE_TO_SUCCESS: MvnCodeFE;
  MOVE_TO_FAIL: MvnCodeFE;
  RENAME_SUCCESS: MvnCodeFE;
  RENAME_FAIL: MvnCodeFE;
  RENAME_UNAUTHORIZED: MvnCodeFE;
  ALREADY_PUBLISHED: MvnCodeFE;
  PUBLISHED_SUCCESS: MvnCodeFE;
  PUBLISHED_FAIL: MvnCodeFE;
  REORDER_FAIL: MvnCodeFE;
  PRINT_FAIL: MvnCodeFE;
  PRINT_UNAUTHORIZED: MvnCodeFE;
  IMAGE_LOAD_FAIL: MvnCodeFE;
} = {
  // Description
  FOLDER_NAME_INPUT_DESCRIPTION: {
    ko: "폴더 이름 입력",
    en: "Enter folder name",
  },
  DRAG_AND_DROP_DESCRIPTION: {
    ko: "Drag & Drop 또는 아래 버튼을 눌러 파일을 선택해주세요.",
    en: "Drag & Drop file here or click the button below to select.",
  },
  DRAG_AND_DROP_SUB_DESCRIPTION: {
    ko: "미발행 문서로 업로드됩니다.",
    en: "The document is uploaded as unpublished.",
  },
  DRAG_AND_DROP_LIMIT: {
    ko: "최대 파일 {count}개까지만 한 번에 업로드할 수 있습니다.",
    en: "You can only upload up to {count} files at a time.",
  },
  DELETE_RESOURCE_DESCRIPTION_1: {
    ko: "이 파일은 영구 삭제됩니다.",
    en: "Are you sure you want to permanently delete this folder/file?",
  },
  DELETE_RESOURCE_DESCRIPTION_2: {
    ko: "삭제하시겠습니까?",
    en: "",
  },
  DUPLICATE_TO_DESCRIPTION: {
    ko: "Manage 권한이 있는 폴더나 파일만 복사가 됩니다.",
    en: "Only folders or files that have Manage permissions can be duplicate.",
  },
  UPLOAD_NOT_ALLOWED_DESCRIPTION: {
    ko: "허용되지 않는 파일 형식입니다.",
    en: "The file format is not allowed.",
  },
  UPLOAD_SIZE_EXCEEDED_DESCRIPTION: {
    ko: "파일 크기가 초과되었습니다.",
    en: "The file size has been exceeded.",
  },
  PUBLISH_DESCRIPTION_1: {
    ko: "최소 보기(View) 권한을 가진 사용자에게 해당 폴더/파일이 보여지게 됩니다.",
    en: "The folder/file will be visible to users with at least view privileges.",
  },
  PUBLISH_DESCRIPTION_2: {
    ko: "발행하시겠습니까?",
    en: "Are you sure you want to publish this folder/file?",
  },

  // TITLE
  ROOM_INFO_TITLE: {
    ko: "Room 정보",
    en: "Room Info",
  },
  ROOM_STATUS_TITLE: {
    ko: "Room 상태",
    en: "Room Status",
  },

  ADD_FOLDER_TITLE: {
    ko: "폴더 추가",
    en: "Add Folder",
  },
  DOWNLOAD_INDEX_TITLE: {
    ko: "인덱스 다운로드",
    en: "Download Index",
  },
  UPLOAD_FILE_TITLE: {
    ko: "파일 업로드",
    en: "Upload File",
  },
  UPLOAD_FOLDER_TITLE: {
    ko: "폴더 업로드",
    en: "Upload Folder",
  },

  COMPRESSING_TITLE: {
    ko: "압축 중",
    en: "compressing",
  },
  COMPRESSED_TITLE: {
    ko: "압축 완료",
    en: "compressed",
  },
  DUPLICATE_TO_TITLE: {
    ko: "복사",
    en: "Duplicate To",
  },
  SELECT_LOCATION_PLACEHOLDER: {
    ko: "위치 선택",
    en: "Select Location",
  },
  MOVE_TO_TITLE: {
    ko: "이동",
    en: "Move To",
  },
  RENAME_TITLE: {
    ko: "이름 변경",
    en: "Rename",
  },
  ENTER_NAME_PLACEHOLDER: {
    ko: "이름 입력",
    en: "Enter name",
  },
  PUBLISH_TITLE: {
    ko: "발행",
    en: "Publish",
  },
  SELECT_FILE_TITLE: {
    ko: "파일 선택",
    en: "Select File",
  },
  RETRY_TITLE: {
    ko: "재시도",
    en: "Retry",
  },
  EMPTY_IMAGE_TITLE: {
    ko: "등록된 데이터가 없습니다.",
    en: "No Data.",
  },
  EMPTY_CONVERT_FAIL_TITLE: {
    ko: "문서 미리보기 생성에 실패했습니다.",
    en: "Failed to generate document preview.",
  },
  EMPTY_LOADING_TITLE: {
    ko: "로딩 중입니다. 잠시만 기다려주세요",
    en: "Loading. Please wait a moment.",
  },
  EMPTY_UNABLE_TITLE: {
    ko: "문서 미리보기를 할 수 없습니다.",
    en: "Unable to preview the document.",
  },

  // Toast
  ADD_FOLDER_SUCCESS: {
    ko: "폴더를 추가했습니다.",
    en: "The folder has been added.",
  },
  ADD_FOLDER_FAIL: {
    ko: "폴더 추가에 실패했습니다.",
    en: "Failed to add the folder.",
  },
  DOWNLOAD_START: {
    ko: "다운로드가 시작되었습니다.",
    en: "The download has started.",
  },
  DOWNLOAD_FAIL: {
    ko: "다운로드를 시작하지 못했습니다.",
    en: "Failed to start download.",
  },
  DOWNLOAD_UNAUTHORIZED: {
    ko: "다운로드에 실패했습니다. 다운로드 권한이 없습니다.",
    en: "Failed to download. You do not have permission to download.",
  },
  INDEX_DOWNLOAD_START: {
    ko: "인덱스 다운로드가 시작되었습니다.",
    en: "The index download has started.",
  },
  INDEX_DOWNLOAD_SUCCESS: {
    ko: "인덱스 다운로드가 완료되었습니다.",
    en: "The index has been downloaded.",
  },
  INDEX_DOWNLOAD_FAIL: {
    ko: "인덱스 다운로드에 실패하였습니다.",
    en: "Failed to download the index.",
  },
  DELETE_RESOURCE_SUCCESS: {
    ko: "파일/폴더가 삭제 되었습니다.",
    en: "File/Folder has been Deleted.",
  },
  DELETE_RESOURCE_FAIL: {
    ko: "파일/폴더가 삭제에 실패하였습니다.",
    en: "Failed to delete File/Folder.",
  },
  DUPLICATE_TO_SUCCESS: {
    ko: "{count} 개의 폴더 및 하위폴더를 복사하였습니다.",
    en: "{count} of folder(s) and/or its subfolder(s) have been duplicated.",
  },
  DUPLICATE_TO_FAIL: {
    ko: "복사에 실패하였습니다.",
    en: "Failed to duplicate the folder/file.",
  },
  UPLOAD_SUCCESS: {
    ko: "파일이 업로드되었습니다.",
    en: "The file has been uploaded.",
  },
  UPLOAD_FAIL: {
    ko: "파일 업로드에 실패하였습니다.",
    en: "Failed to upload the file.",
  },
  MOVE_TO_SUCCESS: {
    ko: "{name} 폴더로 이동했습니다.",
    en: "The file/folder has been moved to the folder {name}",
  },
  MOVE_TO_FAIL: {
    ko: "{name} 폴더 이동에 실패하였습니다.",
    en: "Failed to move to the folder {name}.",
  },
  RENAME_SUCCESS: {
    ko: "이름이 변경되었습니다.",
    en: "The name has been changed.",
  },
  RENAME_FAIL: {
    ko: "이름 변경에 실패하였습니다.",
    en: "Failed to rename folder/file.  ",
  },
  RENAME_UNAUTHORIZED: {
    ko: "이름 변경에 실패하였습니다. 이름 변경 권한이 없습니다.",
    en: "Failed to rename folder/file. You do not have privilege to rename.",
  },
  ALREADY_PUBLISHED: {
    ko: "이미 발행된 문서입니다.",
    en: "The document is already published.",
  },
  PUBLISHED_SUCCESS: {
    ko: "{count}개의 문서가 발행 되었습니다.",
    en: "{count} of folder/file(s) has been published.",
  },
  PUBLISHED_FAIL: {
    ko: "문서 발행에 실패하였습니다.",
    en: "Failed to publish the file/folder.",
  },
  REORDER_FAIL: {
    ko: "순서 변경에 실패하였습니다.",
    en: "Failed to reorder.",
  },
  PRINT_FAIL: {
    ko: "인쇄에 실패하였습니다.",
    en: "Failed to print.",
  },
  PRINT_UNAUTHORIZED: {
    ko: "인쇄에 실패하였습니다. 인쇄 권한이 없습니다.",
    en: "Failed to print. You do not have privilege to print.",
  },
  IMAGE_LOAD_FAIL: {
    ko: "이미지를 불러오지 못했습니다.",
    en: "Failed to load the image.",
  },
};

MvnCodeInitiate(VDR_ROOM_DOCUMENT_LANG);
